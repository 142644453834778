import { variables } from '$lib/config';
import '$lib/polyfills/hasOwn';
import { user as userStore } from '$lib/stores/auth';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { get } from 'svelte/store';

Sentry.init({
	enabled: variables.SENTRY_ENABLED,
	dsn: variables.SENTRY_DSN,
	environment: variables.SENTRY_ENVIRONMENT,
	tracesSampleRate: variables.SENTRY_TRACES_SAMPLE_RATE,
	integrations: []
});

export const handleError = (async () => {
	if (variables.SENTRY_ENABLED) {
		const user = get(userStore);
		if (user) {
			Sentry.setUser({
				email: user.email ?? null,
				id: user.id ?? null,
				username: user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : null
			});

			Sentry.setContext('team', {
				name: user.team?.name ?? null,
				id: user.team?.id ?? null
			});
		}

		handleErrorWithSentry();
	}

	return {
		message: `An error occurred. Please contact support.`
	};
}) satisfies HandleClientError;
